// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-tsx": () => import("./../../../src/pages/cases.tsx" /* webpackChunkName: "component---src-pages-cases-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-documentation-objects-tsx": () => import("./../../../src/pages/documentation/objects.tsx" /* webpackChunkName: "component---src-pages-documentation-objects-tsx" */),
  "component---src-pages-documentation-twig-tsx": () => import("./../../../src/pages/documentation/twig.tsx" /* webpackChunkName: "component---src-pages-documentation-twig-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-knowledge-center-tsx": () => import("./../../../src/pages/knowledge-center.tsx" /* webpackChunkName: "component---src-pages-knowledge-center-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-product-tour-tsx": () => import("./../../../src/pages/product-tour.tsx" /* webpackChunkName: "component---src-pages-product-tour-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-templates-wp-landing-tsx": () => import("./../../../src/templates/WpLanding.tsx" /* webpackChunkName: "component---src-templates-wp-landing-tsx" */),
  "component---src-templates-wp-post-tsx": () => import("./../../../src/templates/WpPost.tsx" /* webpackChunkName: "component---src-templates-wp-post-tsx" */)
}

